import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import UploadImage from "../../assets/images/defimg.jpeg";
import SingleImageSelect from "../Media/SingleImageSelect";
import {
  BrandDropDown,
  CategoriesDropDown,
  DiscountDropDown,
  MediaList,
  TaxDropDown,
  productEdit,
} from "../../service/apiService";
import {
  ValidateCategory,
  ValidatePrice,
  ValidateProductName,
  ValidateQuantity,
  ValidateSku,
  ValidateTax,
  ValidateWeight,
  validateSlug,
} from "../../validation/CustomValidation";
import { CustomValidationError } from "../../Helper/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TextField } from "@mui/material";

const ProductEditForm = ({ onSuccess }) => {
  const [specification, setSpecification] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();
  const { product_id } = location.state || {};
  const [previewImage, setPreviewImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const AppUrl = "https://folcon.stylefitz.in/api/files/";
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [productData, setProductData] = useState({});
  const [thumnailImage, setthumnailImage] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [categoryId, setcategoryId] = useState(null);
  const [discountId, setdiscountId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data?.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });
      CategoryDropdownList();
      BrandDropDownList();
      TaxDropDownList();
      DiscountDropDownList();
      productList();
    }
  }, [token]);
  useEffect(() => {
    setProductFormData({
      ...productformData,
      ...productData,
    });
  }, [productData]);

  const [productformData, setProductFormData] = useState({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    price: "",
    discount: "",
    tax: "",
    category: "",
    brand: "",
    slug: "",
    is_have_variant: "",
    unit: "",
    weight: "",
    returning_date: "",
    is_active: 1,
    thumnail_image: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    price: "",
    discount: "",
    tax: "",
    category: "",
    brand: "",
    slug: "",
    //is_have_variant: "",
    //unit: "",
    weight: "",
    returning_date: "",
    is_active: 1,
    thumnail_image: "",
  });

  const generateSlug = (name) => {
    return name
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-");
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    const capitalizeWords = (text) => {
      return text
        .replace(/([^\w\s])/gi, (match) => match.replace(/\s+/g, ""))
        .replace(
          /\b\w+\b/g,
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
    };

    const transformedValue =
      id === "product_name" ? capitalizeWords(value) : value;

    let updatedFormData = {
      ...productformData,
      [id]: value,
    };

    if (id === "product_name") {
      updatedFormData = {
        ...updatedFormData,
        [id]: transformedValue,
        slug: generateSlug(value),
      };
    }

    setProductFormData(updatedFormData);
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setProductFormData({
      ...productformData,
      is_active: activeValue,
    });
  };
  const handlePreOrderChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setProductFormData({
      ...productformData,
      is_pre_order: activeValue,
    });
  };
  const productList = async () => {
    const response = await productEdit(token, product_id);
    onSuccess(product_id);
    setProductData(response.data);
    setBrandId(response.data.brand_id);
    setcategoryId(response.data.category_id);
    setdiscountId(response.data.discount_id);
    if (response.data.thumnail_image) {
      setthumnailImage(response.data.thumnail_image);
    }
    setDescription(response.data.description);
    setSpecification(response.data.specification);
  };
  const CategoryDropdownList = async () => {
    const CategoriesDropDownList = await CategoriesDropDown(token);
    setCategoryList(CategoriesDropDownList.data);
  };
  const BrandDropDownList = async () => {
    const BrandDropDownList = await BrandDropDown(token);
    setBrandList(BrandDropDownList.data);
  };
  const TaxDropDownList = async () => {
    const TaxDropDownList = await TaxDropDown(token);
    setTaxList(TaxDropDownList.data);
  };
  const DiscountDropDownList = async () => {
    const DiscountDropDownList = await DiscountDropDown(token);
    setDiscountList(DiscountDropDownList.data);
  };

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter((i) => i !== index));
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      closeModal();
    }
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("admin_token");
    const productNameError = ValidateProductName(productformData.product_name);
    const skuError = ValidateSku(productformData.sku);
    const priceError = ValidatePrice(productformData.original_price);
    const taxError = ValidateTax(productformData.tax_id);
    const categoryError = ValidateCategory(
      productformData.category ? productformData.category : categoryId
    );
    const quantityError = ValidateQuantity(productformData.quantity);
    //const unitError = ValidateUnit(productformData.unit_id);
    // const varientError = ValidateVariant(productformData.is_have_variant);
    const weightError = ValidateWeight(productformData.weight);
    const slugError = validateSlug(productformData.slug);
    setValidationErrors({
      product_name: productNameError,
      sku: skuError,
      price: priceError,
      tax: taxError,
      category: categoryError,
      quantity: quantityError,
      weight: weightError,
      slug: slugError,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let imageUrl = null;
      if (selectedFile) {
        const formDataToSend = new FormData();
        formDataToSend.append("file_name", selectedFile);

        const productuploadResponse = await axios.post(
          "https://folcon.stylefitz.in/admin/media-upload",
          formDataToSend,
          config
        );

        if (productuploadResponse.data && productuploadResponse.data.data) {
          imageUrl = productuploadResponse.data.data;
        } else {
          toast.error("Error uploading image. Please try again.");
          return;
        }
      }

      const quantity = parseInt(productformData.quantity, 10);
      const minimum_stock_warning = parseInt(
        productformData.minimum_stock_warning,
        10
      );
      const price = parseInt(productformData.original_price, 10);
      const discount = parseInt(productformData.discount, 10);
      const tax = parseInt(productformData.tax_id, 10);

      const category = parseInt(productformData.category, 10);
      const brand = parseInt(productformData.brand, 10);
      //const is_have_variant = parseInt(productformData.is_have_variant, 10);
      // const unit = parseInt(productformData.unit_id, 10);
      const weight = parseInt(productformData.weight, 10);
      const returning_date = parseInt(productformData.returning_date, 10);

      const createPayload = {
        ...productformData,
        image_path: previewImage.split("/").pop(),
        product_name: productformData.product_name,
        quantity: quantity,
        minimum_stock_warning: minimum_stock_warning,
        price: price,
        discount_id: discount ? discount : discountId,
        tax: tax,
        category_id: category ? category : categoryId,
        brand_id: brand ? brand : 1,
        is_have_variant: 1,
        unit: 1,
        weight: weight,
        returning_date: returning_date,
        sku: productformData.sku,
        slug: generateSlug(productformData.product_name),
        description: description,
        specification: specification,
        product_id: productformData.product_id,
        thumnail_image: productData.thumnail_image.split("/").pop(),
      };

      if (
        !productNameError &&
        !skuError &&
        !priceError &&
        !categoryError &&
        !quantityError &&
        !taxError &&
        !weightError
      ) {
        const productcreateResponse = await axios.post(
          "https://folcon.stylefitz.in/admin/update-product",
          createPayload,
          config
        );
        if (productcreateResponse.data.status === "success") {
          toast.success("Product updated successfully!");
          // setPreviewImage(null);
          // setSelectedFile(null);
        } else {
          toast.error("Product creation failed. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error creating product:", error);
      toast.error("Product creation failed. Please try again.");
    }
  };
  useEffect(() => {
    if (productformData.thumnail_image) {
      setPreviewImage(productformData.thumnail_image);
    }
  }, [productformData.thumnail_image]);

  return (
    <>
      <form className="product-form">
        {/* first row **************************** */}
        <div className="form-row">
          {/* Product name  */}
          <div className="form-group">
            <TextField
              id="product_name"
              label="Product Name"
              variant="outlined"
              placeholder="Type here"
              value={productformData.product_name}
              onChange={handleChange}
              error={!!validationErrors.product_name}
              helperText={validationErrors.product_name}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
          </div>

          <div className="form-group">
            <TextField
              id="slug"
              label="Slug"
              variant="outlined"
              placeholder="Type here"
              name="slug"
              value={productformData.slug}
              onChange={handleChange}
              InputProps={{
                readOnly: true,
                style: { backgroundColor: "white" },
              }}
              fullWidth
            />
            <CustomValidationError error={validationErrors.slug} />
          </div>
        </div>

        {/* Second row **************************** */}

        <div className="form-row">
          {/* Description */}

          <div className="form-group">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <div className="editor-wrapper">
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                onReady={(editor) => {
                  // Apply styles directly to the content area
                  editor.editing.view.document
                    .getRoot()
                    .parentElement.classList.add("custom-editor-content");
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "blockQuote",
                    "|",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "undo",
                    "redo",
                  ],
                  bodyClass: "custom-editor-content", // Apply the custom class to the editor body
                }}
              />
            </div>
          </div>

          {/* Specification */}
          <div className="form-group">
            <label htmlFor="inputPassword5" className="form-label">
              Specification
            </label>
            <div className="editor-wrapper">
              <CKEditor
                editor={ClassicEditor}
                data={specification}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSpecification(data);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "blockQuote",
                    "|",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "undo",
                    "redo",
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* third row **************************** */}
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="brand" className="form-label">
              Brand
            </label>
            <select
              id="brand"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={handleChange}
            >
              {!brandList ? (
                <option value="">No Brand...</option>
              ) : brandList.length === 0 ? (
                <option value="">No brand found</option>
              ) : (
                <>
                  <option value="">Select brand</option>
                  {brandList.map((brand) => (
                    <option
                      key={brand.id}
                      value={brand.id}
                      selected={brandId === brand.id}
                    >
                      {brand.brand_name.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          {/* Category */}
          <div className="form-group">
            <label htmlFor="inputState" className="form-label">
              Category
            </label>
            <select
              id="category"
              className="react-select-container"
              classNamePrefix="react-select"
              // value={productformData.category}
              onChange={handleChange}
            >
              {!categoryList ? (
                <option value="">No Data...</option>
              ) : categoryList.length === 0 ? (
                <option value="">No category found</option>
              ) : (
                <>
                  <option value="">Select Category</option>
                  {categoryList.map((category) => (
                    <option
                      key={category.id}
                      value={category.id}
                      selected={categoryId === category.id}
                    >
                      {category.category_name.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
            <CustomValidationError error={validationErrors.category} />
          </div>
          {/* Discount */}
          <div className="form-group">
            <label htmlFor="inputState" className="form-label">
              Discount
            </label>
            <select
              id="discount"
              onChange={handleChange}
              className="react-select-container"
              classNamePrefix="react-select"
            >
              {!discountList ? (
                <option value="">No Data...</option>
              ) : discountList.length === 0 ? (
                <option value="">No Discount found</option>
              ) : (
                <>
                  <option value="">Select Discount</option>
                  {discountList.map((discount) => (
                    <option
                      key={discount.id}
                      value={discount.id}
                      selected={productformData.discount_id === discount.id}
                    >
                      {discount.name.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          {/* Tax */}
          <div className="form-group">
            <label htmlFor="inputState" className="form-label">
              Tax
            </label>
            <select
              id="tax"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={handleChange}
            >
              {!taxList ? (
                <option value="">No Data...</option>
              ) : taxList.length === 0 ? (
                <option value="">No tax found</option>
              ) : (
                <>
                  <option value="">Select Tax</option>
                  {taxList.map((tax) => (
                    <option
                      key={tax.id}
                      value={tax.id}
                      selected={productformData.tax_id === tax.id}
                    >
                      {tax.label.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        </div>

        {/* fourth row **************************** */}
        <div className="form-row">
          {/* Sku */}
          <div className="form-group">
            <TextField
              id="sku"
              label="Sku"
              variant="outlined"
              placeholder="Type here"
              value={productformData.sku}
              onChange={handleChange}
              error={!!validationErrors.sku} // Show error state if validationErrors.sku is present
              helperText={validationErrors.sku} // Display validation error message
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
          </div>
          {/* quantity */}
          <div className="form-group">
            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              placeholder="Type here"
              name="quantity"
              value={productformData.quantity}
              onChange={handleChange}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
            <CustomValidationError error={validationErrors.quantity} />
          </div>
          <div className="form-group">
            <TextField
              id="minimum_stock_warning"
              label="Minimum Stock Warning"
              placeholder="Type here"
              value={productformData.minimum_stock_warning}
              InputProps={{
                style: { backgroundColor: "white" },
              }}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
          {/* Price */}
          <div className="form-group">
            <TextField
              id="original_price"
              label="Price"
              variant="outlined"
              placeholder="Type here"
              name="original_price"
              value={productformData.original_price || ""}
              onChange={handleChange}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />

            <CustomValidationError error={validationErrors.price} />
          </div>
        </div>
        {/* 5th row **************************** */}

        <div className="form-row">
          <div className="form-group">
            <label>Thumbnail Image</label>
            <br />
            {previewImage ? (
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={
                    previewImage.startsWith(AppUrl)
                      ? previewImage
                      : `${AppUrl}${previewImage}`
                  }
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    width: "100px",
                    height: "100px",
                  }}
                />
                <button
                  type="button"
                  onClick={handleDeletePreview}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    backgroundColor: "red",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            ) : (
              <img src={UploadImage} alt="Alt" width="100px" />
            )}
            <button
              type="button"
              className="btn btn-primary mt-2 btn-sm btn-block"
              onClick={openModal}
            >
              Select Image
            </button>
          </div>

          {/* PreOrder */}
          <div className="form-group">
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              PreOrder
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="toggle"
                checked={productformData.is_pre_order === 1}
                onChange={handlePreOrderChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              Status
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="toggle"
                checked={productformData.is_active === 1}
                onChange={handleToggleChange}
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <hr></hr>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>

        <SingleImageSelect
          isOpen={modalIsOpen}
          handleClose={closeModal}
          handleImageClick={handleImageClick}
          selectedImages={selectedImages}
          imageUrl={imageUrl}
          token={token}
          setImageUrl={setImageUrl}
        />
      </form>
    </>
  );
};

export default ProductEditForm;
